'use client';

import { Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { AxiosError } from 'axios';
import Image from 'next/image';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import isEqual from 'react-fast-compare';

import { useGetMarketsSuspenseQuery } from '@/graphql/markets.gql.generated';
import { useGetShippersSuspenseQuery } from '@/graphql/shippers.gql.generated';
import { usePrinter } from '@/hooks/usePrinter';
import { OrderDoc } from '@/lib/db/models';
import { OrderErrorType } from '@/lib/db/order/literal';
import ottoLogo from '@/public/Otto_GmbH_logo.svg';
import { DhlBillingNumbers, MarketProvider, OrderStatus } from '@/schema.types';

import { useRxdb } from '../RxdbProvider';
import { useUserSessionAccounts } from '../UserSessionProvider/user-session-hooks';

import { MarketplaceConnector } from './connectors/marketplace/MarketplaceConnector';
import { OttoMarketplaceConnector } from './connectors/marketplace/OttoMarketplaceConnector';
import { DhlShipperConnector } from './connectors/shipper/DhlShipperConnector';
import { ShipperConnector } from './connectors/shipper/ShipperConnector';
import { tenantConfig } from './fixtures/tenantConfig';
import { getProductMap } from './shopify-products';
import { WarehouseContext, WarehouseActionsContext, initialWarehouseContext } from './warehouse-context';
import type { WarehouseActionsContext as TWarehouseActionsContext, WarehouseConnectorLink } from './warehouse-types';

import '@packages/appsync/utils/polyfill';
/**
 * our Warehouse
 *
 * should take care of
 *
 * - managing orders (fetching, submitting, refetching etc.)
 * - managing sync with peers
 * - managing credentials
 * - requesting labels and tracking numbers from our backend
 */

export const WarehouseProvider: React.FC<React.PropsWithChildren<{}>> = memo(function WarehouseProvider({ children }) {
	const { data: marketsData } = useGetMarketsSuspenseQuery();
	const { data: shippersData } = useGetShippersSuspenseQuery();

	const { currentAccount } = useUserSessionAccounts();

	const db = useRxdb();

	if (!db) {
		throw new Error(`Cannot use warehouse provider without db`);
	}

	const marketConnectorsRef = useRef<Map<string, MarketplaceConnector<any, any>>>(new Map());
	const shippersConnectorsRef = useRef<Map<string, ShipperConnector>>(new Map());

	const isSetupRunningRef = useRef(false);

	const [isWarehouseReady, setIsWarehouseReady] = useState(false);

	const abortController = useMemo(() => new AbortController(), []);

	const { print } = usePrinter();

	// 	const {channel} = useChannel("[?rewind=100]your-channel-name", (message) => {
	//     // List the last 100 messages on the channel
	//     console.log(message);
	// });

	// const { channel } = useChannel(`${currentAccount.pk}:warehouse`, (message) => {
	// 	// List the last 100 messages on the channel
	// 	console.log(message);
	// });

	// TODO: store jobId AND the notificationId in a map and REMOVE them on the next call
	const jobsRef = useRef<
		Map<
			string,
			{
				currentJobId?: string;
				currentNotificationId?: string;
				currentBatchSize?: number;
				mostRecentOrderExternalId?: string;
			}
		>
	>(new Map());

	const upsertOrders = useCallback<WarehouseConnectorLink['upsertOrders']>(
		async (rawOrders, market) => {
			if (rawOrders.length === 0) return;

			const products = await getProductMap(currentAccount.pk);
			const orderDocs = await db.orders.upsertFromMarketplace(market, rawOrders, products);
			const openOrdersCount = orderDocs.filter((order) => order.status === OrderStatus.Open).length;
			const currentJob = jobsRef.current.get(market.id)!;

			currentJob.currentBatchSize = (currentJob?.currentBatchSize ?? 0) + openOrdersCount;

			const message = (
				<Text size="sm">
					{currentJob.currentBatchSize} offene
					{currentJob.currentBatchSize > 1 ? ' Bestellungen wurden' : ' Bestellung wurde'} abgeholt
				</Text>
			);

			if (currentJob.currentBatchSize === 0) return;

			if (currentJob?.currentNotificationId) {
				notifications.update({
					id: currentJob.currentNotificationId,
					message,
				});
			} else {
				currentJob.currentNotificationId = notifications.show({
					autoClose: 10_000,
					styles: {
						icon: {
							backgroundColor: 'transparent',
						},
					},
					icon: <Image width={30} priority src={ottoLogo} alt="Otto" />,
					message,
					onClose: () => {
						currentJob.currentBatchSize = 0;
						currentJob.currentJobId = undefined;
						currentJob.currentNotificationId = undefined;
					},
				});
			}
		},
		[currentAccount.pk, db.orders],
	);

	useEffect(() => {
		function setupMarkets() {
			if (isSetupRunningRef.current) {
				return;
			}

			isSetupRunningRef.current = true;

			// now get products

			if (shippersData.shippers?.dhl) {
				if (!shippersConnectorsRef.current.has('dhl')) {
					const shippingConnector = new DhlShipperConnector(shippersData.shippers.dhl);

					shippersConnectorsRef.current.set('dhl', shippingConnector);
				}
			}

			for (const market of marketsData.markets) {
				if (!marketConnectorsRef.current.has(market.id)) {
					let marketplaceConnector: MarketplaceConnector<unknown, unknown> | undefined = undefined;

					switch (market.provider) {
						case MarketProvider.Otto:
							marketplaceConnector = new OttoMarketplaceConnector(market, { upsertOrders });
							break;
					}

					if (!marketplaceConnector) {
						throw new Error(`Could not create marketplace connector for market ${market.provider} ${market.id}`);
					} else {
						marketConnectorsRef.current.set(market.id, marketplaceConnector);
					}
				}
			}

			setIsWarehouseReady(true);

			isSetupRunningRef.current = false;
		}

		void setupMarkets();

		return () => {};
	}, [marketsData.markets, shippersData.shippers?.dhl, upsertOrders]);

	/** ----------------------------------------------------------------------------------------------
	 * start syncing orders !
	 * _______________________________________________________________________________________________ */

	useEffect(() => {
		let timeoutId: NodeJS.Timeout;

		async function syncOrders() {
			if (!isWarehouseReady) {
				return;
			}

			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			for (const connector of marketConnectorsRef.current.values()) {
				const mostRecentOrder = await db?.orders
					.findOne({
						selector: {
							marketProvider: connector.market.provider as any,
							marketMerchantId: connector.market.merchantId,
						},
						sort: [{ uat: 'desc' }],
					})
					.exec();

				const currentJobId = util.autoUlid();

				jobsRef.current.set(connector.market.id, { currentJobId, currentBatchSize: 0 });

				const since = mostRecentOrder?.uat ? new Date(mostRecentOrder.uat) : undefined;

				// @ts-expect-error blub
				window.raw = mostRecentOrder?.raw;

				await connector.fetchOrders(abortController, {
					since,
					jobId: currentJobId,
					mostRecentOrderRaw: !mostRecentOrder?.raw ? undefined : mostRecentOrder.toJSON().raw,
				});
			}

			// refresh every minute
			// @ts-expect-error bun types
			timeoutId = setTimeout(syncOrders, 1000 * 60 * 1);
		}

		void syncOrders();

		return () => {
			clearTimeout(timeoutId);
			// abortController.abort();
		};
	}, [abortController, db.orders, isWarehouseReady]);

	const requestShippingLabels = useCallback<TWarehouseActionsContext['requestShippingLabels']>(
		async (orders, options, overrides) => {
			const [provider, productId] = options.product!.split(':');
			const ordersWithTrackingInfo: OrderDoc[] = [];

			if (provider === 'DHL') {
				if (shippersConnectorsRef.current.has('dhl')) {
					const connector = shippersConnectorsRef.current.get('dhl')! as DhlShipperConnector;

					for (const order of orders) {
						let orderDoc = await db.orders.findOne().where('id').eq(order.id).exec();

						if (!orderDoc) {
							throw new Error(`Could not find order in rxdb ${order.id}`);
						}

						// here we need to get the sender address
						const senderAddress = tenantConfig[currentAccount.pk].address; // as OrderDoc['senderAddress'];

						if (!isEqual(orderDoc.senderAddress, senderAddress)) {
							orderDoc = await orderDoc.updateCRDT({
								ifMatch: {
									$set: {
										uat: new Date().toISOString(),
										senderAddress,
									},
								},
							});
						}

						const { shipmentResult, returnShipmentResult, errors } = await connector.createLabel(
							orderDoc.toMutableJSON(),
							{
								product: productId as keyof DhlBillingNumbers,
								returnProduct: 'V07PAK',
								includeReturnLabel: options.includeReturnLabel,
								services: options.services,
							},
							overrides,
						);

						if (errors) {
							for (const error of errors) {
								if (!order.errors?.find((err: any) => err.type === error.type)) {
									orderDoc = await orderDoc.updateCRDT({
										ifMatch: {
											$push: {
												errors: error,
											},
										},
									});
								}
							}

							continue;
						} else {
							const item = shipmentResult.data?.items?.at(0);

							// HINT: this should not be necessary
							if (!item || !item.label?.url || !item.shipmentNo || !returnShipmentResult?.shipmentNo) {
								if (!order.errors?.find((error: any) => error.type === OrderErrorType.SHIPMENT)) {
									orderDoc = await orderDoc.updateCRDT({
										ifMatch: {
											$push: {
												errors: {
													type: OrderErrorType.SHIPMENT,
													message: 'Could not create shipment',
													createdAt: new Date().toISOString(),
												},
											},
										},
									});
								}
								console.error('Insufficient item', { item, returnShipmentResult });

								throw new Error(`Insufficient data returned for ${order.id}`, {
									cause: { shipmentResult, returnShipmentResult },
								});
							}

							console.log({ shipmentResult, returnShipmentResult });

							const shippingDocuments: OrderDoc['shippingDocuments'] = {
								provider: 'DHL',
								trackingNumber: item.shipmentNo,
								returnTrackingNumber: returnShipmentResult?.shipmentNo,
							};

							if (!isEqual(orderDoc.shippingDocuments, shippingDocuments)) {
								orderDoc = await orderDoc.updateCRDT({
									ifMatch: {
										$set: {
											uat: new Date().toISOString(),
											shippingDocuments: {
												provider: 'DHL',
												trackingNumber: item.shipmentNo,
												returnTrackingNumber: returnShipmentResult?.shipmentNo,
											},
										},
									},
								});
							}

							const json = orderDoc.toMutableJSON();

							// console.log('updated order', json);

							ordersWithTrackingInfo.push(json);

							/** ----------------------------------------------------------------------------------------------
							 * PRINT LABELS
							 * _______________________________________________________________________________________________ */

							print([
								{
									pdfFileUrl: item.label.url,
									pagesToPrint: '1',
									referenceId: `${orderDoc.marketProvider}|${orderDoc.marketMerchantId}|${orderDoc.eid2 ?? orderDoc.eid}`,
								},
							]);
						}
					}
				}
			}

			return ordersWithTrackingInfo;
		},
		[currentAccount.pk, db.orders, print],
	);

	const shipOrders = useCallback<TWarehouseActionsContext['shipOrders']>(
		async (orders) => {
			const submittedOrders: OrderDoc[] = [];

			for (const order of orders) {
				const orderDoc = await db.orders.findOne().where('id').eq(order.id).exec();

				if (!orderDoc) {
					throw new Error(`Could not find order in rxdb ${order.id}`);
				}

				// Skip orders that have no shipping documents
				if (!orderDoc?.shippingDocuments) continue;

				const connector = marketConnectorsRef.current.get(order.marketId);

				if (!connector) {
					throw new Error(`Could not find connector for market ${order.marketId}`);
				}

				const abortController = new AbortController();

				try {
					const result = await connector.shipOrder(abortController, order);

					const updatedDoc = await orderDoc.updateCRDT({
						selector: {
							status: {
								$nin: [OrderStatus.Submitted, OrderStatus.Sent],
							},
						},
						ifMatch: {
							$set: {
								shipmentId: result.shipmentId,
								status: OrderStatus.Submitted,
								uat: new Date().toISOString(),
								sat: new Date().toISOString(),
							},
						},
					});

					const json = updatedDoc.toMutableJSON();

					// void channel.publish('order-update', {
					// 	action: 'submitted',
					// 	id: json.id,
					// 	// marketProvider: json.marketProvider,
					// 	// marketMerchantId: json.marketMerchantId,
					// 	// marketId: json.marketId,
					// });

					submittedOrders.push(json);
				} catch (err: any) {
					console.error(err);
					let message = ('message' in err ? err.message : 'Fehler bei der Übermittlung') + '\n';
					let cause = JSON.stringify(err, null, 2);

					if (err instanceof AxiosError) {
						cause = JSON.stringify(err.toJSON());

						if (err.response && 'errors' in err.response?.data) {
							for (const error of err.response.data.errors) {
								message += `${error.title}: ${error.detail} ${error.jsonPath} \n`;
							}
						}
					}

					await orderDoc.updateCRDT({
						ifMatch: {
							$push: {
								errors: {
									type: OrderErrorType.SUBMISSION,
									message,
									createdAt: new Date().toISOString(),
									cause,
								},
							},
						},
					});

					// submittedOrders.push(updatedDoc.toMutableJSON());
				}
			}

			//  check if order is an otto order and if requirements are met, if not show a modal !
			return submittedOrders;
		},
		[, /* channel */ db.orders],
	);

	return (
		<WarehouseContext.Provider value={{ ...initialWarehouseContext, markets: marketsData.markets }}>
			<WarehouseActionsContext.Provider
				value={{
					print: (...args) => {
						console.log('print', args);
						return Promise.resolve();
					},
					requestShippingLabels,
					shipOrders,
				}}
			>
				{children}
			</WarehouseActionsContext.Provider>
		</WarehouseContext.Provider>
	);
}, isEqual);

import { Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { QuickshipRealtimeClientProvider, useQuickshipRealtimeClient } from '@packages/realtime/quickship-react';
import { params } from '@packages/static/config';
import { IconExclamationCircle } from '@tabler/icons-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { PropsWithChildren } from 'react';
import { isPresent } from 'ts-extras';

import { othersLockInfoAtom, ownLockInfoAtom } from '@/components/QuickShip/state-quickship';
import { availablePrintersAtom, currentPrinterAtom, isGraphQLSubscriptionEstablishedAtom } from '@/state/state-system';

import { useUserSessionAccounts, useUserSessionTokens } from '../UserSessionProvider/user-session-hooks';
import { LogoutReason, userSessionService } from '../UserSessionProvider/user-session-state-machine';

export const RealtimeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const isGraphQLSubscriptionEstablished = useAtomValue(isGraphQLSubscriptionEstablishedAtom);

	return <RealtimeInitProvider>{isGraphQLSubscriptionEstablished ? children : null}</RealtimeInitProvider>;
};

const RealtimeInitProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const tokens = useUserSessionTokens();
	const { currentAccount } = useUserSessionAccounts();

	const auth = React.useCallback(
		() =>
			fetch(params.apiOrigin['production'] + '/auth/ably', {
				method: 'POST',
				headers: { authorization: `Bearer ${tokens.oxidAccessToken.stringValue}` },
			}).then((res) => res.json()),
		[tokens.oxidAccessToken],
	);

	return !tokens.oxidAccessToken || !currentAccount.pk ? null : (
		<QuickshipRealtimeClientProvider tenantId={currentAccount.pk} ablyAuth={auth}>
			<>
				<MessageHandler />
				{children}
			</>
		</QuickshipRealtimeClientProvider>
	);
};

const MessageHandler: React.FC = () => {
	const { client } = useQuickshipRealtimeClient();
	const setOthersLockInfo = useSetAtom(othersLockInfoAtom);
	const setAvailablePrinters = useSetAtom(availablePrintersAtom);
	const ownLockInfo = useAtomValue(ownLockInfoAtom);
	const [currentPrinter, setCurrentPrinter] = useAtom(currentPrinterAtom);

	React.useEffect(() => {
		client.setLockedBundleHash(ownLockInfo.lockedSkuHash);
	}, [client, ownLockInfo.lockedSkuHash]);

	React.useEffect(() => {
		if (currentPrinter) {
			void client.selectPrinter(currentPrinter?.id);
		}
	}, [client, currentPrinter]);

	// TODO: improve the following so that we have assigned functions that we can `off` when unmounting
	React.useEffect(() => {
		const unsetPrinterIfNoCloudprintClientsFoundTimeoutId = setTimeout(() => {
			if (client?.printers?.length === 0) {
				setCurrentPrinter(null);
			}
		}, 3000);

		client.on('account_abuse_detected', () => {
			console.log('account_abuse_detected');
			userSessionService.send({ type: 'LOGOUT', data: { reason: LogoutReason.MULTIPLE_ACCOUNT_INSTANCES } });
		});

		client.on('printers_changed', (printers) => {
			setAvailablePrinters(printers.filter(isPresent));
			if (printers.length > 0 && unsetPrinterIfNoCloudprintClientsFoundTimeoutId) {
				clearTimeout(unsetPrinterIfNoCloudprintClientsFoundTimeoutId);
			}
		});

		client.on('locks_changed', (locks) => {
			setOthersLockInfo(locks.others);
		});

		client.on('active_printer_gone', () => {
			setCurrentPrinter(null);
			notifications.show({
				styles: {
					icon: {
						backgroundColor: 'transparent',
					},
				},
				icon: <IconExclamationCircle width={30} />,
				message: (
					<>
						<Text>Drucker konnte nicht gefunden werden. Bitte auswählen.</Text>
					</>
				),
			});
		});

		client.on('orders_submitted', (orders) => console.log('orders_submitted', orders));

		try {
			setAvailablePrinters(client?.printers);
			setOthersLockInfo(client?.getLocks().others);
		} catch (err) {
			//
		}

		// HINT: we must initially set all data that can change as the events might happen before the initial render

		return () => {
			client.removeAllListeners();
		};
	}, [client, setAvailablePrinters, setCurrentPrinter, setOthersLockInfo]);

	return null;
};

import { Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useQuickshipRealtimeClient } from '@packages/realtime/quickship-react';
import { PrintJob } from '@packages/static/cloudprint';
import * as Sentry from '@sentry/nextjs';
import { IconExclamationCircle } from '@tabler/icons-react';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';

import { currentPrinterAtom } from '@/state/state-system';

async function isFileAvailable(url: string) {
	try {
		const response = await fetch(url, { method: 'GET' });

		// Check if response is ok (status in the range 200-299)
		if (!response.ok) {
			return false;
		}

		// Read only the first byte of the response
		const reader = response.body?.getReader();
		void reader?.read();

		// Close the stream
		void reader?.cancel();

		return true;
	} catch (error) {
		return false;
	}
}

export const usePrinter = () => {
	const currentPrinter = useAtomValue(currentPrinterAtom);

	const { client } = useQuickshipRealtimeClient();

	const print = useCallback<(jobs: Omit<PrintJob, 'printerName'>[]) => void>(
		async (jobs) => {
			if (!currentPrinter?.name) {
				throw new Error('No printer uri found');
			}

			for (const job of jobs) {
				const isAvailable = await isFileAvailable(job.pdfFileUrl);

				if (!isAvailable) {
					notifications.show({
						id: `${job.pdfFileUrl}-file-not-found`,
						autoClose: 10_000,
						styles: {
							icon: {
								backgroundColor: 'transparent',
							},
						},
						icon: <IconExclamationCircle color="red" width={30} />,

						message: (
							<>
								<Text>Label für Bestellung {job.referenceId} kann nicht abgerufen werden</Text>
							</>
						),
					});
				} else {
					const result = await client.createPrintJob({ ...job, printerName: currentPrinter.name });

					result.match(
						(job) => console.log('successfully created printjob'),
						(err) => {
							Sentry.captureException(err);
							notifications.show({
								id: `${job.pdfFileUrl}-print-error`,
								autoClose: 10_000,
								styles: {
									icon: {
										backgroundColor: 'transparent',
									},
								},
								icon: <IconExclamationCircle color="red" width={30} />,
								message: (
									<>
										<Text>Label für Bestellung {job.referenceId} konnte nicht gedruckt werden</Text>
										<Text size="xs">{err.message}</Text>
									</>
								),
							});
							console.log('error creating printjob', err);
						},
					);
				}
			}
		},
		[client, currentPrinter?.name],
	);

	return {
		print,
	};
};

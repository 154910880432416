import { Button } from '@mantine/core';

export const FullscreenAppToggle = () => {
	return (
		<Button
			size="xs"
			variant="transparent"
			style={{ position: 'fixed', zIndex: 1000, top: '1rem', left: 100 }}
			onClick={() => {
				if (document.fullscreenElement) {
					void document.exitFullscreen();
				} else {
					void document.documentElement.requestFullscreen();
				}
			}}
		>
			Toggle Fullscreen
		</Button>
	);
};

/* tslint:disable */
/* eslint-disable */
/**
 * Parcel DE Returns API
 * # Introduction ## Overview                  Note:   This is the specification of the DP-DHL Group Parcel DE Returns API. This web service allows business customers to create return labels on demand.  # Scenarios ## Main Scenario: Creating a returnlabel This is achieved by posting a return order to the URI \'/rest/orders\'. The service will respond with a return label. ## Querying to get receiver locations The single scenario supported by this service is the determination of the receiver\'s location. This is achieved by getting a location to the URI \'/rest/locations\'. The service will respond with a Receiver.
 *
 * The version of the OpenAPI document: 1.0.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, setApiKeyToObject, setBasicAuthToObject, setSearchParams, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI } from '../base';
/**
 * LocationsApi - axios parameter creator
 * @export
 */
export const LocationsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * The response should be a list of Receiver, each containing the following information
         * @summary Get available return locations
         * @param {Country} [countryCode] The ISO3 code of the location
         * @param {string} [postalCode] The postal code code of the location
         * @param {string} [receiverId] The receiver id of the location
         * @param {string} [billingNumber]
         * @param {number} [maxResult] The result should be containable.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocations: async (countryCode, postalCode, receiverId, billingNumber, maxResult, options = {}) => {
            const localVarPath = `/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "dhl-api-key", configuration);
            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);
            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }
            if (postalCode !== undefined) {
                localVarQueryParameter['postalCode'] = postalCode;
            }
            if (receiverId !== undefined) {
                localVarQueryParameter['receiverId'] = receiverId;
            }
            if (billingNumber !== undefined) {
                localVarQueryParameter['billingNumber'] = billingNumber;
            }
            if (maxResult !== undefined) {
                localVarQueryParameter['maxResult'] = maxResult;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * LocationsApi - functional programming interface
 * @export
 */
export const LocationsApiFp = function (configuration) {
    const localVarAxiosParamCreator = LocationsApiAxiosParamCreator(configuration);
    return {
        /**
         * The response should be a list of Receiver, each containing the following information
         * @summary Get available return locations
         * @param {Country} [countryCode] The ISO3 code of the location
         * @param {string} [postalCode] The postal code code of the location
         * @param {string} [receiverId] The receiver id of the location
         * @param {string} [billingNumber]
         * @param {number} [maxResult] The result should be containable.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocations(countryCode, postalCode, receiverId, billingNumber, maxResult, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocations(countryCode, postalCode, receiverId, billingNumber, maxResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * LocationsApi - factory interface
 * @export
 */
export const LocationsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = LocationsApiFp(configuration);
    return {
        /**
         * The response should be a list of Receiver, each containing the following information
         * @summary Get available return locations
         * @param {LocationsApiGetLocationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocations(requestParameters = {}, options) {
            return localVarFp.getLocations(requestParameters.countryCode, requestParameters.postalCode, requestParameters.receiverId, requestParameters.billingNumber, requestParameters.maxResult, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * LocationsApi - object-oriented interface
 * @export
 * @class LocationsApi
 * @extends {BaseAPI}
 */
export class LocationsApi extends BaseAPI {
    /**
     * The response should be a list of Receiver, each containing the following information
     * @summary Get available return locations
     * @param {LocationsApiGetLocationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    getLocations(requestParameters = {}, options) {
        return LocationsApiFp(this.configuration).getLocations(requestParameters.countryCode, requestParameters.postalCode, requestParameters.receiverId, requestParameters.billingNumber, requestParameters.maxResult, options).then((request) => request(this.axios, this.basePath));
    }
}

import { Group, GroupProps, Select } from '@mantine/core';
import { IconPrinter } from '@tabler/icons-react';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';

import { availablePrintersAtom, currentPrinterAtom } from '@/state/state-system';

export const PrinterSelect: React.FC<GroupProps> = (props) => {
	const availablePrinters = useAtomValue(availablePrintersAtom);

	const [currentPrinter, setCurrentPrinter] = useAtom(currentPrinterAtom);

	const [printerId, setPrinterId] = useState<string | null>(null);

	const handleSelect = useCallback(
		(id: string | null) => {
			if (id) {
				const printer = availablePrinters.find((device) => device.id === id);
				if (!printer) return;
				setCurrentPrinter(printer);
				setPrinterId(printer.id);
			}
		},
		[availablePrinters, setCurrentPrinter],
	);

	useEffect(() => {
		if (!currentPrinter) {
			setPrinterId(null);
		} else if (!printerId) {
			const initialPrinter = availablePrinters.find((device) => device.id === currentPrinter.id);
			if (initialPrinter) {
				setPrinterId(initialPrinter.id);
			}
		}
	}, [availablePrinters, currentPrinter, printerId]);

	return (
		<Group {...props} gap="xs">
			<IconPrinter style={{ width: 16, height: 16 }} stroke={1.5} color="var(--mantine-color-blue-filled)" />
			<Select
				value={printerId}
				onChange={handleSelect}
				w="240px"
				variant="filled"
				radius="xl"
				size="xs"
				placeholder={availablePrinters.length === 0 ? 'Keine Drucker gefunden' : 'Drucker auswählen'}
				data={availablePrinters.map((device) => ({ value: device.id, label: device.name }))}
			/>
		</Group>
	);
};

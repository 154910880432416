import type * as Types from '../schema.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublishMessageMutationVariables = Types.Exact<{
  input: Types.MessageInput;
}>;


export type PublishMessageMutation = { __typename?: 'Mutation', publishMessage: { __typename?: 'Message', id: string, from: string, to?: string | null, topic: Types.MessageTopic, created_at: any, payload?: any | null, tenant_pk: string } };

export type OnMessageSubscriptionVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['AWSJSON']['input']>;
}>;


export type OnMessageSubscription = { __typename?: 'Subscription', onMessage?: { __typename?: 'Message', id: string, from: string, to?: string | null, topic: Types.MessageTopic, created_at: any, payload?: any | null, tenant_pk: string } | null };

export declare const PublishMessage: import("graphql").DocumentNode;
export declare const OnMessage: import("graphql").DocumentNode;

export const PublishMessageDocument = gql`
    mutation PublishMessage($input: MessageInput!) {
  publishMessage(input: $input) {
    id
    from
    to
    topic
    created_at
    payload
    tenant_pk
  }
}
    `;
export type PublishMessageMutationFn = Apollo.MutationFunction<PublishMessageMutation, PublishMessageMutationVariables>;

/**
 * __usePublishMessageMutation__
 *
 * To run a mutation, you first call `usePublishMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMessageMutation, { data, loading, error }] = usePublishMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishMessageMutation(baseOptions?: Apollo.MutationHookOptions<PublishMessageMutation, PublishMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishMessageMutation, PublishMessageMutationVariables>(PublishMessageDocument, options);
      }
export type PublishMessageMutationHookResult = ReturnType<typeof usePublishMessageMutation>;
export type PublishMessageMutationResult = Apollo.MutationResult<PublishMessageMutation>;
export type PublishMessageMutationOptions = Apollo.BaseMutationOptions<PublishMessageMutation, PublishMessageMutationVariables>;
export const OnMessageDocument = gql`
    subscription OnMessage($filter: AWSJSON) {
  onMessage(filter: $filter) {
    id
    from
    to
    topic
    created_at
    payload
    tenant_pk
  }
}
    `;

/**
 * __useOnMessageSubscription__
 *
 * To run a query within a React component, call `useOnMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnMessageSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnMessageSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnMessageSubscription, OnMessageSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnMessageSubscription, OnMessageSubscriptionVariables>(OnMessageDocument, options);
      }
export type OnMessageSubscriptionHookResult = ReturnType<typeof useOnMessageSubscription>;
export type OnMessageSubscriptionResult = Apollo.SubscriptionResult<OnMessageSubscription>;
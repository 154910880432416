import { useEffect, useState } from 'react';

import { OrderDoc } from '@/lib/db/models';
import { useRxdb } from '@/providers/RxdbProvider';
import { OrderStatus } from '@/schema.types';

export const useOpenOrdersWithErrors = () => {
	const db = useRxdb();

	const [orders, setOrders] = useState<OrderDoc[]>([]);

	useEffect(() => {
		if (!db) return;

		const query = db.orders
			.find({
				selector: {
					status: { $eq: OrderStatus.Open },
					errors: { $exists: true },
				},
			})
			.sort({ cat: 'asc' });

		const subscription = query.$.subscribe(setOrders);

		return () => subscription.unsubscribe();
	}, [db]);

	return orders;
};

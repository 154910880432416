import { Box, Button, Group } from '@mantine/core';
import { useActor } from '@xstate/react';
import { useAtomValue } from 'jotai';

import { useUserSessionAccounts } from '@/providers/UserSessionProvider/user-session-hooks';
import { userSessionService } from '@/providers/UserSessionProvider/user-session-state-machine';
import { topBarItemsAtom } from '@/state/state';

import { PrinterSelect } from './PrinterSelect';

export const Topbar = () => {
	const [, send] = useActor(userSessionService);
	const { currentAccount } = useUserSessionAccounts();

	const topBarItems = useAtomValue(topBarItemsAtom);

	return (
		<Box
			style={{
				zIndex: 1,
				top: 0,
				position: 'sticky',
				backdropFilter: 'blur(10px)',
				WebkitBackdropFilter: 'blur(10px)',
				height: 'calc(2 * var(--mantine-spacing-xl))',
				paddingInline: 'calc(0.5 * var(--mantine-spacing-xl))',
				display: 'grid',
				alignItems: 'center',
				width: '100%',
				backgroundColor: 'color-mix(in srgb, var(--mantine-color-body) 30%, transparent)',
				gridTemplateColumns: 'auto 1fr auto auto',
				gap: '1.5rem',
			}}
		>
			<Group>
				{Array.from(topBarItems.entries()).map(([key, item]) => (!item ? null : <Box key={key}>{item}</Box>))}
			</Group>

			<PrinterSelect style={{ gridColumn: 3 }} />

			<Button
				variant="subtle"
				// color="var(--paper-background-color)"
				fw={700}
				onClick={() => send({ type: 'SELECT_ACOUNT' })}
				size="xs"
				style={{
					// color: 'var(--text-color)',
					cursor: 'pointer',
					justifySelf: 'flex-end',
					display: 'inline-block',
					// borderColor: 'var(--mantine-color-dimmed)',
					// borderWidth: '1px',
					// borderStyle: 'solid',
					borderRadius: 20,
				}}
			>
				{currentAccount.tenant?.name}
			</Button>
		</Box>
	);
};

import { IconUserFilled } from '@tabler/icons-react';

import { IUserState } from '@/state/types';

export const roleMapping = {
	owner: {
		text: 'Administrator:in',
		color: 'pink',
	},
	shipper: {
		text: 'Verpacker:in',
		color: 'blue',
	},
} as const;

export const userStateToAvatar = (user: IUserState | null) => {
	if (user) {
		if (user.firstname && user.lastname) {
			return user.firstname[0] + user.lastname[0];
		}

		if (user.firstname) {
			return user.firstname[0];
		}

		if (user.lastname) {
			return user.lastname[0];
		}
	}

	return <IconUserFilled size={20} />;
};

/* tslint:disable */
/* eslint-disable */
/**
 * Parcel DE Returns API
 * # Introduction ## Overview                  Note:   This is the specification of the DP-DHL Group Parcel DE Returns API. This web service allows business customers to create return labels on demand.  # Scenarios ## Main Scenario: Creating a returnlabel This is achieved by posting a return order to the URI \'/rest/orders\'. The service will respond with a return label. ## Querying to get receiver locations The single scenario supported by this service is the determination of the receiver\'s location. This is achieved by getting a location to the URI \'/rest/locations\'. The service will respond with a Receiver.
 *
 * The version of the OpenAPI document: 1.0.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export * from './api/locations-api';
export * from './api/orders-api';

/* tslint:disable */
/* eslint-disable */
/**
 * Parcel DE Returns API
 * # Introduction ## Overview                  Note:   This is the specification of the DP-DHL Group Parcel DE Returns API. This web service allows business customers to create return labels on demand.  # Scenarios ## Main Scenario: Creating a returnlabel This is achieved by posting a return order to the URI \'/rest/orders\'. The service will respond with a return label. ## Querying to get receiver locations The single scenario supported by this service is the determination of the receiver\'s location. This is achieved by getting a location to the URI \'/rest/locations\'. The service will respond with a Receiver.
 *
 * The version of the OpenAPI document: 1.0.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, setApiKeyToObject, setBasicAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI } from '../base';
/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Creates a return label by given information.
         * @summary Create a return label.
         * @param {LabelType} [labelType] Controls which documents are returned.
         * @param {ReturnOrder} [ReturnOrder] The request body contains the details of the return label that should be created. E.g. sender, references and shipment details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReturnOrder: async (labelType, ReturnOrder, options = {}) => {
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "dhl-api-key", configuration);
            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);
            if (labelType !== undefined) {
                localVarQueryParameter['labelType'] = labelType;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(ReturnOrder, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function (configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration);
    return {
        /**
         * Creates a return label by given information.
         * @summary Create a return label.
         * @param {LabelType} [labelType] Controls which documents are returned.
         * @param {ReturnOrder} [ReturnOrder] The request body contains the details of the return label that should be created. E.g. sender, references and shipment details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReturnOrder(labelType, ReturnOrder, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReturnOrder(labelType, ReturnOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration, basePath, axios) {
    const localVarFp = OrdersApiFp(configuration);
    return {
        /**
         * Creates a return label by given information.
         * @summary Create a return label.
         * @param {OrdersApiCreateReturnOrderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReturnOrder(requestParameters = {}, options) {
            return localVarFp.createReturnOrder(requestParameters.labelType, requestParameters.ReturnOrder, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * Creates a return label by given information.
     * @summary Create a return label.
     * @param {OrdersApiCreateReturnOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    createReturnOrder(requestParameters = {}, options) {
        return OrdersApiFp(this.configuration).createReturnOrder(requestParameters.labelType, requestParameters.ReturnOrder, options).then((request) => request(this.axios, this.basePath));
    }
}

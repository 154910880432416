'use client';

import { Box } from '@mantine/core';
import { AnimatePresence } from 'framer-motion';
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';
import dynamic from 'next/dynamic';

import { FullscreenAppToggle } from '@/components/FullscreenAppToggle';
import { AppLoadingScreen } from '@/components/LoadingScreen';
import { NavbarMinimal } from '@/components/Navbar/NavbarMinimal';
import { OfflineOverlay } from '@/components/OfflineOverlay';
import { RealtimeMessageHandler } from '@/components/RealtimeMessageHandler';
import { Topbar } from '@/components/Topbar/Topbar';
import { WarehouseDebug } from '@/components/WarehouseDebug/WarehouseDebug';
import { RealtimeProvider } from '@/providers/RealtimeProvider/RealtimeProvider';
import { RxdbProvider } from '@/providers/RxdbProvider';
import { WarehouseProvider } from '@/providers/WarehouseProvider/WarehouseProvider';
import { css } from '@/styled-system/css';

// if (process.env.NODE_ENV !== 'development' && typeof window !== 'undefined') {
// 	LogRocket.init('oxid/ecom-webapp');
// 	setupLogRocketReact(LogRocket);
// }

const styles = css({
	display: 'grid',
	gridTemplate: `
	"sidebar content" 100vh
	/ auto 1fr;
	`,
});

const AppPrelude = dynamic(() => import('@/components/AppPrelude/AppPrelude'), {
	ssr: false,
	loading: () => <AppLoadingScreen message="Initialisierung" />,
});

/* much of this should be in a single provider ! instead here should be navigation, sidebar etc */
const DashboardLayout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
	return (
		<>
			<WarehouseDebug />
			<AppPrelude key="prelude">
				<OfflineOverlay />
				<AnimatePresence>
					<RxdbProvider>
						<RealtimeProvider>
							<RealtimeMessageHandler />
							<WarehouseProvider>
								<Box className={styles}>
									<NavbarMinimal />
									<FullscreenAppToggle />
									<Box
										style={{
											overflowY: 'auto',
											gridArea: 'content',
											display: 'grid',
											gridTemplateRows: 'auto 1fr',
										}}
									>
										<Topbar />
										<Box style={{ display: 'grid' }}>{children}</Box>
									</Box>
								</Box>
							</WarehouseProvider>
						</RealtimeProvider>
					</RxdbProvider>
				</AnimatePresence>
			</AppPrelude>
		</>
	);
};

export default DashboardLayout;

'use client';

import { Box, Button, Group, Paper, Stack, Text, Title } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

const MDiv = motion.div;

export const WarehouseDebug: React.FC<{}> = () => {
	const [isVisible, setIsVisible] = useState(false);

	useHotkeys([
		[
			'ctrl+E',
			() => {
				setIsVisible((v) => !v);
			},
		],
	]);

	return (
		<AnimatePresence>
			{isVisible && (
				<MDiv
					style={{
						zIndex: 2000,
						position: 'fixed',
						inset: 0,
						backdropFilter: 'blur(10px)',
						display: 'grid',
						justifyContent: 'stretch',
						alignContent: 'stretch',
						padding: '2.5rem',
					}}
					key="warehouse-debug"
					initial={{ opacity: 0, transform: 'scale(0.99)' }}
					animate={{ opacity: 1, transform: 'scale(1.0)' }}
					exit={{ opacity: 0, transform: 'scale(0.99)', transition: { duration: 0.2 } }}
				>
					<Paper
						w="100%"
						h="100%"
						p="md"
						style={{
							backgroundColor: 'color-mix(in oklch, var(--mantine-color-body) 90%, transparent)',
							overflowY: 'scroll',
						}}
					>
						<Box style={{ position: 'relative' }}>
							<Stack>
								<Stack align="start" mb="xl">
									<Title style={{ position: 'sticky', top: 0 }}>🐞 DEBUGGING</Title>

									<Box
										p="lg"
										style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '1.5rem', alignItems: 'center' }}
									>
										<Text>Lokalen Datenbestand löschen</Text>
										<Button
											color="red"
											onClick={async () => {
												for (const db of await indexedDB.databases()) {
													db.name && indexedDB.deleteDatabase(db.name);
												}
											}}
										>
											Datenbank zurücksetzen
										</Button>
										<Text>Anmeldungsprobleme beheben</Text>
										<Button
											color="red"
											onClick={() => {
												localStorage.clear();
											}}
										>
											Auth-Speicher zurücksetzen
										</Button>
									</Box>
								</Stack>
								<Stack>
									<Group>
										<Button
											color="red"
											onClick={() => {
												const err = new Error();
												console.log(err.stack);
												throw err;
											}}
										>
											Fehler auslösen
										</Button>
										<Text>Debugging Test</Text>
									</Group>
								</Stack>
								{/* <Stack>
									<Title style={{ position: 'sticky', top: 0 }}>_____lala</Title>
									<Text mih="200">hello</Text>
									<Text mih="200">hello</Text>
									<Text mih="200">hello</Text>
									<Text mih="200">hello</Text>
									<Text mih="200">hello</Text>
									<Text mih="200">hello</Text>
									<Text mih="200">hello</Text>
									<Text mih="200">hello</Text>
									<Text mih="200">hello</Text>
									<Text mih="200">hello</Text>
								</Stack> */}
							</Stack>
						</Box>
					</Paper>
				</MDiv>
			)}
		</AnimatePresence>
	);
};

export interface ShippingOverrides {
	weightGrams?: number;
	dimensions?: {};
	label?: {
		// here we could add manual label format instructions
	};
}

export interface CreateLabelOptions {
	includeReturnLabel?: boolean;
}

export abstract class ShipperConnector {
	//
}

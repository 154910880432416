/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
	OrdersV4Api,
	PartnerOrderListOrdersV4,
	PartnerOrderOrdersV4,
	ProductVariationProductsV3,
	ProductsV3Api,
	ShipmentsV1Api,
	createOttoClient,
} from '@packages/data/otto-browser';
import * as CONFIG from '@packages/static/config';
import * as Sentry from '@sentry/nextjs';
import type { AxiosInstance, AxiosResponse } from 'axios';
import { sub } from 'date-fns';
import { IterableElement } from 'type-fest';
import { z } from 'zod';

import { GetMarketsQuery } from '@/graphql/markets.gql.generated';
import { OrderDoc } from '@/lib/db/models';
import { oxidAccessTokenAtom } from '@/state/state';
import { globalStore } from '@/state/store';

import { oxidSenderAddressToOttoShipmentsAddress, processOttoPositionItems } from '../../../../lib/db/utils/otto';
import { WarehouseProduct, WarehouseConnectorLink } from '../../warehouse-types';

import { FetchOrderOptions, MarketplaceConnector } from './MarketplaceConnector';

type OttoWarehouseProduct = WarehouseProduct<ProductVariationProductsV3>;

export class OttoMarketplaceConnector extends MarketplaceConnector<PartnerOrderOrdersV4, ProductVariationProductsV3> {
	private client: AxiosInstance;

	private ordersApi: OrdersV4Api;
	private productsApi: ProductsV3Api;
	private shipmentsApi: ShipmentsV1Api;

	/**
	 * TODO: document our proxy !!!
	 * @param market
	 * @param warehouseLink
	 */
	constructor(
		market: IterableElement<GetMarketsQuery['markets']>,
		public readonly warehouseLink: WarehouseConnectorLink,
	) {
		super(market, warehouseLink);

		this.client = createOttoClient(process.env.NEXT_PUBLIC_OTTO_ENVIRONMENT);

		this.client.defaults.baseURL = `${process.env.NEXT_PUBLIC_OXID_API_ORIGIN}/proxy/otto`;
		this.client.defaults.headers.common[CONFIG.params.headers.proxyApp] = this.market.id;
		this.client.defaults.withCredentials = true;

		this.ordersApi = new OrdersV4Api(undefined, undefined, this.client);
		this.productsApi = new ProductsV3Api(undefined, undefined, this.client);
		this.shipmentsApi = new ShipmentsV1Api(undefined, undefined, this.client);
	}

	/** ██████████████████████████████████████████████████████████████████████████████████████████████
	 * Class implementations
	 * ███████████████████████████████████████████████████████████████████████████████████████████████ */

	// TODO must be finished and the for each order , transform into WarehouseOrder and call

	destroy(): void {
		console.log('destroying otto connector - but why?');
	}

	async fetchOrders(abortController: AbortController, options: FetchOrderOptions) {
		this.client.defaults.headers.common['Authorization'] = globalStore.get(oxidAccessTokenAtom);

		this.client.defaults.signal = abortController.signal;

		let lastFetchedItemCount = 0;
		let totalFetchedItemCount = 0;

		let orders: PartnerOrderOrdersV4[] = [];

		const earliestSince = sub(new Date(), { days: 21 });
		let currentSince = earliestSince;

		const hasSince = typeof options.since !== 'undefined';

		if (options.since) {
			currentSince = options.since;
		}

		let nextcursor: string = '';

		let result: AxiosResponse<PartnerOrderListOrdersV4, any> | null = null;

		const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

		let retryCount = 0;

		do {
			/** ----------------------------------------------------------------------------------------------
			 * When fetching orders, we distinguish by
			 * - a since date was provided := we have at least one order in our db and must continue to pull updates since
			 * - we have no orders yet, so only fetch processable orders from the last 21 days
			 * _______________________________________________________________________________________________ */

			// TODO add `debug` info here

			try {
				result =
					nextcursor.length > 0
						? await this.ordersApi.ordersV4FindPartnerOrders({ nextcursor, limit: 100 })
						: !hasSince
							? await this.ordersApi.ordersV4FindPartnerOrders({
									orderColumnType: 'ORDER_DATE',
									orderDirection: 'ASC',
									/**
									 * all items of the order must be in the same state
									 * which is set by using the 'BUCKET' flag
									 */
									mode: 'BUCKET',
									fulfillmentStatus: 'PROCESSABLE',
									fromOrderDate: currentSince.toISOString(),
									// toOrderDate: new Date().toISOString(),
									limit: 100,
								})
							: await this.ordersApi.ordersV4FindPartnerOrders({
									orderColumnType: 'LAST_MODIFIED_DATE',
									orderDirection: 'ASC',
									/**
									 * all items of the order must be in the same state
									 * which is set by using the 'BUCKET' flag
									 */
									mode: 'BUCKET',
									fromDate: currentSince.toISOString(),
									limit: 100,
								});

				if (result.status === 499) {
					console.debug('Client cancelled request');
					return;
				}

				if (result.status !== 200) {
					throw new Error(`Error fetching orders from Otto API: ${result.statusText}`);
				}

				retryCount = 0;
			} catch (error: any) {
				if (error instanceof Error) {
					Sentry.captureException(error, {
						extra: {
							market: this.market,
						},
					});
				}
			}

			if (result) {
				nextcursor = '';

				if (result.data.links) {
					const nextLink = result.data.links.find((link) => link.rel === 'next');

					if (nextLink?.href) {
						nextcursor = new URL(nextLink.href, 'http://localhost').searchParams.get('nextcursor') ?? '';
					}
				}

				lastFetchedItemCount = result.data.resources?.length ?? 0;
				totalFetchedItemCount = totalFetchedItemCount + lastFetchedItemCount;

				for (const order of result.data.resources ?? []) {
					// HINT: we push all in here as even though the most recent order might be the one we have in our db,
					// the status might have changed. We run a diff of the RAW data and the .raw property of the order
					// in our static order methods that will take care of such cases
					orders.push(order);
				}

				await this.warehouseLink.upsertOrders(orders, this.market, options.jobId);

				orders = [];
			}

			if (!result && nextcursor.length === 0) {
				await wait(2000);
				retryCount += 1;

				if (retryCount > 5) {
					console.error('Could not fetch orders from Otto API');
					return;
				}
			}
		} while (!result || nextcursor.length > 0);
	}

	async shipOrder(abortController: AbortController, order: OrderDoc): Promise<{ shipmentId: string }> {
		this.client.defaults.headers.common['Authorization'] = globalStore.get(oxidAccessTokenAtom);
		/** ██████████████████████████████████████████████████████████████████████████████████████████████
		 // WARN: ONLY ASSIGN TRACKING NUMBERS TO POSITION ITEMS THAT ARE NOT CANCELLED !!!! 🔴🔴🔴🔴🔴🔴🔴🔴
		 * ███████████████████████████████████████████████████████████████████████████████████████████████ */

		const raw = order.raw as PartnerOrderOrdersV4;

		if (!order.shippingDocuments) {
			throw new Error(`Missing shipping documents for Otto order ${order.eid}`);
		}

		if (!order.shippingDocuments?.returnTrackingNumber) {
			throw new Error(`Missing return tracking numbers for Otto order ${order.eid}`);
		}

		const { openItems } = processOttoPositionItems(raw);

		try {
			const result = await this.shipmentsApi.shipmentsV1CreatedAndSentShipmentUsingPOST({
				CreateShipmentRequestShipmentsV1: {
					/** ----------------------------------------------------------------------------------------------
					 * We only ship only those positionItems the items that are in the order and not cancelled
					 * _______________________________________________________________________________________________ */
					positionItems: openItems.map((item) => {
						return {
							positionItemId: item.positionItemId,
							trackingNumber: order.shippingDocuments!.trackingNumber,
							returnTrackingKey: {
								carrier: order.shippingDocuments!.provider,
								trackingNumber: order.shippingDocuments!.returnTrackingNumber!,
							},
							salesOrderId: raw.salesOrderId,
						};
					}),
					shipFromAddress: oxidSenderAddressToOttoShipmentsAddress(order.senderAddress),
					shipDate: new Date().toISOString(),
					trackingKey: {
						carrier: order.shippingDocuments.provider,
						trackingNumber: order.shippingDocuments?.trackingNumber,
					},
				},
			});

			console.log('shippadiship result', result);

			const { shipmentId } = result.data;

			if (shipmentId === undefined) {
				throw new Error(`Error shipping order ${order.eid}: ${result.statusText}`);
			}

			return { shipmentId };
		} catch (err) {
			throw err;
		}
	}

	async fetchProducts(abortController: AbortController, since?: Date): Promise<OttoWarehouseProduct[]> {
		return {} as any;
	}

	async getProductBySku(abortController: AbortController, sku: string): Promise<OttoWarehouseProduct> {
		return {} as any;
	}

	/** ██████████████████████████████████████████████████████████████████████████████████████████████
	 * HELPERS
	 * ███████████████████████████████████████████████████████████████████████████████████████████████ */
}

const ottoAddressStruct = z.object({
	firstName: z.string().optional(),
	lastName: z.string(),
	street: z.string(),
	houseNumber: z.string().optional(),
	city: z.string(),
	countryCode: z.string(),
	zipCode: z.string(),
});

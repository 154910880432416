import type * as Types from '../schema.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMarketsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMarketsQuery = { __typename?: 'Query', markets: Array<{ __typename?: 'Market', id: string, merchantId: string, provider: Types.MarketProvider, environment?: Types.ApiEnvironment | null }> };

export declare const GetMarkets: import("graphql").DocumentNode;

export const GetMarketsDocument = gql`
    query GetMarkets {
  markets {
    id
    merchantId
    provider
    environment
  }
}
    `;

/**
 * __useGetMarketsQuery__
 *
 * To run a query within a React component, call `useGetMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarketsQuery(baseOptions?: Apollo.QueryHookOptions<GetMarketsQuery, GetMarketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarketsQuery, GetMarketsQueryVariables>(GetMarketsDocument, options);
      }
export function useGetMarketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarketsQuery, GetMarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarketsQuery, GetMarketsQueryVariables>(GetMarketsDocument, options);
        }
export function useGetMarketsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMarketsQuery, GetMarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMarketsQuery, GetMarketsQueryVariables>(GetMarketsDocument, options);
        }
export type GetMarketsQueryHookResult = ReturnType<typeof useGetMarketsQuery>;
export type GetMarketsLazyQueryHookResult = ReturnType<typeof useGetMarketsLazyQuery>;
export type GetMarketsSuspenseQueryHookResult = ReturnType<typeof useGetMarketsSuspenseQuery>;
export type GetMarketsQueryResult = Apollo.QueryResult<GetMarketsQuery, GetMarketsQueryVariables>;
export function refetchGetMarketsQuery(variables?: GetMarketsQueryVariables) {
      return { query: GetMarketsDocument, variables: variables }
    }
import { notifications } from '@mantine/notifications';
import { deserializeMessage } from '@packages/static/realtime';
import React, { useEffect } from 'react';
import { match } from 'ts-pattern';

import { useOnMessageSubscription } from '@/graphql/message.gql.generated';
import { MessageTopic } from '@/schema.types';

/**
 * should
 * - setup a realtime connection filtered by `from:c#CloudPrint#`
 * - parse messages and store all printers alongside the host in our jotai store
 * - do not use context !
 */
export const RealtimeMessageHandler: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
	const { data, loading, error } = useOnMessageSubscription();

	useEffect(() => {
		if (error) {
			notifications.show({ title: 'Realtime error', message: error?.message, color: 'red' });
			console.error('error in realtime provider', error);
		}
	}, [error]);

	// on mount, we want to get a list of all printers
	useEffect(() => {}, []);

	useEffect(() => {
		// console.log('data', data);

		if (data?.onMessage) {
			match(data.onMessage.topic)
				.with(MessageTopic.Hello, () => {
					const m = deserializeMessage(data.onMessage!, MessageTopic.Hello);
					console.log(m);
				})
				.with(MessageTopic.Echo, () => {
					const m = deserializeMessage(data.onMessage!, MessageTopic.Echo);
					console.log(m);
				})
				.with(MessageTopic.LockOrders, () => {
					const m = deserializeMessage(data.onMessage!, MessageTopic.LockOrders);
					console.log(m);
				})
				.with(MessageTopic.UnlockOrders, () => {
					const m = deserializeMessage(data.onMessage!, MessageTopic.UnlockOrders);
					console.log(m);
				})
				// .with(MessageTopic.RequestPrinters, () => {
				// 	const m = deserializeMessage(data.onMessage!, MessageTopic.RequestPrinters);
				// 	console.log(m);
				// })
				// .with(MessageTopic.PublishPrinters, () => {
				// 	const m = deserializeMessage(data.onMessage!, MessageTopic.PublishPrinters);
				// 	// console.log(m);

				// 	const client = { accountSk: m.from, printers: m.payload ?? [], lastSync: new Date() };

				// 	globalStore.set(
				// 		cloudPrintClientsAtomFamily({
				// 			accountSk: m.from,
				// 			client,
				// 		}),
				// 		() => client,
				// 	);
				// })
				// TODO: filter this one out !
				.with(MessageTopic.Print, () => {
					const m = deserializeMessage(data.onMessage!, MessageTopic.Print);
					console.log(m);
				})
				.with(MessageTopic.UpdatePrinterStatus, () => {
					const m = deserializeMessage(data.onMessage!, MessageTopic.UpdatePrinterStatus);
					console.log(m);
				})
				.otherwise((topic) => {
					console.warn('ignored topic', topic);
				});
		}
		// todo: get accountId by 'from' field
		// put id into cloudPrintClientAccountIdsAtom and use Set to make unique
	}, [data]);

	return null;
};

import type * as Types from '../schema.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShippersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetShippersQuery = { __typename?: 'Query', shippers?: { __typename?: 'Shippers', dhl?: { __typename?: 'DHLApp', id: string, profile?: string | null, environment?: Types.ApiEnvironment | null, updated_at?: any | null, defaultLabelFormat?: Types.DhlLabelFormat | null, receiverId?: string | null, credentials?: { __typename?: 'DHLCredentials', password?: string | null, username?: string | null } | null, billingNumbers?: { __typename?: 'DHLBillingNumbers', V01PAK?: string | null, V62WP?: string | null, V07PAK?: string | null } | null } | null } | null };

export declare const GetShippers: import("graphql").DocumentNode;

export const GetShippersDocument = gql`
    query GetShippers {
  shippers {
    dhl {
      id
      profile
      environment
      updated_at
      defaultLabelFormat
      receiverId
      credentials {
        password
        username
      }
      billingNumbers {
        V01PAK
        V62WP
        V07PAK
      }
    }
  }
}
    `;

/**
 * __useGetShippersQuery__
 *
 * To run a query within a React component, call `useGetShippersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShippersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShippersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShippersQuery(baseOptions?: Apollo.QueryHookOptions<GetShippersQuery, GetShippersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShippersQuery, GetShippersQueryVariables>(GetShippersDocument, options);
      }
export function useGetShippersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShippersQuery, GetShippersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShippersQuery, GetShippersQueryVariables>(GetShippersDocument, options);
        }
export function useGetShippersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetShippersQuery, GetShippersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetShippersQuery, GetShippersQueryVariables>(GetShippersDocument, options);
        }
export type GetShippersQueryHookResult = ReturnType<typeof useGetShippersQuery>;
export type GetShippersLazyQueryHookResult = ReturnType<typeof useGetShippersLazyQuery>;
export type GetShippersSuspenseQueryHookResult = ReturnType<typeof useGetShippersSuspenseQuery>;
export type GetShippersQueryResult = Apollo.QueryResult<GetShippersQuery, GetShippersQueryVariables>;
export function refetchGetShippersQuery(variables?: GetShippersQueryVariables) {
      return { query: GetShippersDocument, variables: variables }
    }
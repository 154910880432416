import { NotificationData, notifications } from '@mantine/notifications';
import { nanoid } from 'nanoid';
import type { IterableElement } from 'type-fest';

import { GetMarketsQuery } from '@/graphql/markets.gql.generated';
import { OrderDoc } from '@/lib/db/models';
import { errorsAtom } from '@/state/state';
import { globalStore } from '@/state/store';

import { WarehouseConnectorLink, WarehouseOrder, WarehouseProduct } from '../../warehouse-types';

export interface FetchOrderOptions {
	since?: Date;
	jobId?: string;
	/**
	 * we use this to figure out if the first order returned is already in our list
	 */
	mostRecentOrderRaw?: unknown;
}

export interface IMarketplaceConnector {
	market: GetMarketsQuery['markets'][number];

	warehouseLink: WarehouseConnectorLink;

	// HINT: we don't need a `getOrders` as we push straight from Connector into WarehouseProvider through the ContextLink Methods
	fetchOrders(abortController: AbortController, options: FetchOrderOptions): Promise<void>;
	shipOrder(abortController: AbortController, order: OrderDoc): Promise<{ shipmentId: string }>;
	fetchProducts(abortController: AbortController, since?: Date): Promise<WarehouseProduct[]>;
	getProductBySku(abortController: AbortController, sku: string): Promise<WarehouseProduct | undefined>;
}

export abstract class MarketplaceConnector<
	TRawOrder,
	TRawProduct,
	TWarehouseOrder extends WarehouseOrder = WarehouseOrder<TRawOrder>,
	TWarehouseProduct extends WarehouseProduct = WarehouseProduct<TRawProduct>,
> implements IMarketplaceConnector
{
	/** ----------------------------------------------------------------------------------------------
	 * static methods to get marketplace connector or create a new one
	 * _______________________________________________________________________________________________ */

	private static connectors: Map<string, MarketplaceConnector<any, any>> = new Map();

	/**
	 * state
	 */

	public readonly market: GetMarketsQuery['markets'][number];

	protected orders: Map<string, TWarehouseOrder> = new Map();
	protected products: Map<string, TWarehouseProduct> = new Map();

	protected minOrdersThreshold: number = 50;
	protected maxOrdersThreshold: number = 100;

	/**
	 * Constructor
	 * @param marketId
	 */

	constructor(
		market: IterableElement<GetMarketsQuery['markets']>,
		public readonly warehouseLink: WarehouseConnectorLink,
	) {
		this.market = market;

		MarketplaceConnector.connectors.set(market.id, this);
	}

	/** ----------------------------------------------------------------------------------------------
	 * general methods for each marketplace connector
	 * _______________________________________________________________________________________________ */

	protected notify(data: NotificationData) {
		notifications.show(data);
	}
	protected reportError(message: string, data?: { error?: Error; details?: Record<string, unknown> }) {
		globalStore.set(errorsAtom, (errors) => [...errors, { message, id: nanoid(), timestamp: new Date(), ...data }]);
	}

	// protected getMostRecentOrderDate(): Date {
	// 	let mostRecentOrderDate: Date = sub(new Date(), { days: 15 });

	// 	this.orders.forEach((order) => {
	// 		if (order.orderDate > mostRecentOrderDate) {
	// 			mostRecentOrderDate = order.orderDate;
	// 		}
	// 	});

	// 	return mostRecentOrderDate;
	// }

	/**
	 * abstract methods need to be implemented by the concrete marketplace connector
	 */

	// TODO should kill timeouts etc
	abstract destroy(): void;

	abstract fetchOrders(abortController: AbortController, options: FetchOrderOptions): Promise<void>;
	abstract shipOrder(abortController: AbortController, order: OrderDoc): Promise<{ shipmentId: string }>;
	abstract fetchProducts(abortController: AbortController, since?: Date): Promise<TWarehouseProduct[]>;
	abstract getProductBySku(abortController: AbortController, sku: string): Promise<TWarehouseProduct>;

	static marketplaces: Map<string, MarketplaceConnector<any, any>> = new Map();
}

/**
 * TODO: put this into it's own package @package/realtime and also expose react components and hooks!
 * Why? because we cannot use this with appsync and therefore it's NOT static anymore (we have throw)
 */
export const from = {
    cloudPrintClient: (accountSk) => intoFilter({ from: { eq: to.cloudPrintClient(accountSk) } }),
    anyCloudPrintClient: () => intoFilter({ from: { beginsWith: to.anyCloudPrintClient() } }),
    user: (accountSk) => intoFilter({ from: { eq: to.user(accountSk) } }),
    anyUser: () => intoFilter({ from: { beginsWith: 'u#' } }),
};
export const fromWithTopic = (sender, topic) => {
    return intoFilter({ ...fromFilter(sender), topic: { eq: topic } });
};
const intoFilter = (input) => ({ filter: JSON.stringify(input) });
const fromFilter = (input) => JSON.parse(input.filter);
export const to = {
    cloudPrintClient: (accountSk) => `c#CloudPrint#${accountSk}`,
    anyCloudPrintClient: () => 'c#CloudPrint#',
    user: (accountSk) => `u#${accountSk}`,
    anyUser: () => 'u#',
};
export const serializeMessage = (message) => ({
    ...message,
    payload: JSON.stringify(message.payload),
});
export const deserializeMessage = (message, expectedTopic) => {
    if (message.topic !== expectedTopic) {
        throw new Error(`Expected message topic ${expectedTopic} but got ${message.topic}`);
    }
    const deserializedPayload = message.payload ? JSON.parse(message.payload) : undefined;
    return {
        ...message,
        payload: deserializedPayload,
    };
};

import { Tooltip, UnstyledButton, rem } from '@mantine/core';
import { IconHome2 } from '@tabler/icons-react';
import { Route } from 'next';
import Link from 'next/link';

import { cx } from '@/styled-system/css';

import classes from './NavbarMinimal.module.css';

interface NavbarLinkProps {
	icon: typeof IconHome2;
	label: string;
	active?: boolean;
	onClick?(): void;
	href?: string;
	rounded?: boolean;
	children?: React.ReactNode;
	color?: string;
}

export function NavbarLink({ icon: Icon, label, active, onClick, href, rounded, children, color }: NavbarLinkProps) {
	const props = href ? { href: href as Route, component: Link } : { onClick: onClick as () => void };

	return (
		<Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
			<UnstyledButton
				{...(props as any)}
				className={cx(classes.link, rounded && classes.rounded)}
				data-active={active || undefined}
			>
				{children ? children : <Icon color={color} style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
			</UnstyledButton>
		</Tooltip>
	);
}

import { Indicator } from '@mantine/core';
import { IconUrgent } from '@tabler/icons-react';

import { routes } from '@/config';
import { useOpenOrdersWithErrors } from '@/hooks/useOpenOrdersWithErrors';

import { NavbarLink } from './NavbarLink';

export const OrdersArchiveLink = () => {
	const ordersWithErrors = useOpenOrdersWithErrors();

	return (
		<Indicator
			disabled={ordersWithErrors.length === 0}
			fw={800}
			styles={{ indicator: { fontSize: 8 } }}
			color="var(--mantine-color-red-7)"
			size={20}
			label={ordersWithErrors.length}
		>
			<NavbarLink
				href={routes.archive.path}
				icon={routes.archive.icon}
				label="Archiv"
				color="var(--mantine-color-yellow-7)"
			/>
		</Indicator>
	);
};

import { Box, BoxProps, Stack, Text, Title } from '@mantine/core';
import { useNetwork } from '@mantine/hooks';
import { AnimatePresence, motion } from 'framer-motion';

const MBox = motion(Box as React.FC<BoxProps>);

export const OfflineOverlay: React.FC = () => {
	const { online } = useNetwork();

	return (
		<AnimatePresence>
			{!online && (
				<MBox
					initial={{ opacity: 0 }}
					exit={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					style={{
						zIndex: 9999,
						position: 'fixed',
						inset: 0,
						backgroundColor: 'var(--mantine-color-body)',
						display: 'grid',
						placeContent: 'center',
						textAlign: 'center',
					}}
				>
					<Stack>
						<Title order={1}>Uh. Oh. Offline.</Title>
						<Box>
							<Text c="dimmed" size="lg">
								Wir können leider keine Verbindung zum Internet herstellen.
							</Text>
							<Text c="dimmed" size="sm">
								Bitte stelle sicher, dass Dein Netzwerk mit dem Internet verbunden ist.
							</Text>
						</Box>
					</Stack>
				</MBox>
			)}
		</AnimatePresence>
	);
};

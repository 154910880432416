'use client';

import { Center, Stack, Space, Box, Text, Avatar, BoxComponentProps } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

import { routes } from '@/config';
import { userStateToAvatar } from '@/lib/mappings';
import {
	useUserSessionAccounts,
	useUserSessionActions,
	useUserSessionUser,
} from '@/providers/UserSessionProvider/user-session-hooks';

import { NavbarLink } from './NavbarLink';
import classes from './NavbarMinimal.module.css';
import { OrdersArchiveLink } from './OrdersArchiveLink';

const ColorSchemeToggle = dynamic(() => import('./ColorSchemeToggle'), {
	ssr: false,
});

export function NavbarMinimal(props: BoxComponentProps) {
	const pathname = usePathname();

	const { currentAccount } = useUserSessionAccounts();
	const user = useUserSessionUser();

	const { logout } = useUserSessionActions();

	return (
		<Box component="nav" className={classes.navbar} mr="md">
			<Center mih="42px" style={{ position: 'relative' }}>
				<Text ff="Unbounded" fw={800} style={{ position: 'absolute' }}>
					OXID
				</Text>
			</Center>

			<Stack justify="center" gap="xl">
				<NavbarLink
					href={routes.quickship.path}
					active={pathname === routes.quickship.path}
					icon={routes.quickship.icon}
					label="QuickShip"
					color="var(--mantine-color-blue-7)"
				/>

				<OrdersArchiveLink />

				{user?.isSuperUser && (
					<NavbarLink
						href={routes.dashboard.path}
						active={pathname === routes.dashboard.path}
						icon={routes.dashboard.icon}
						label="Dashboard"
						color="var(--mantine-color-grape-7)"
					/>
				)}
			</Stack>

			<Stack justify="center" style={{ display: 'grid', justifyItems: 'center' }} gap={'md'} mb="lg">
				{/* <NavbarLink icon={IconSwitchHorizontal} label="Change account" /> */}

				<Box mih="50px">
					<ColorSchemeToggle />
				</Box>
				<Space h="lg" />

				{currentAccount?.role === 'owner' && (
					<NavbarLink
						href={routes.settings.path}
						active={pathname.startsWith(routes.settings.path)}
						icon={routes.settings.icon}
						label="Einstellungen"
					/>
				)}

				<NavbarLink
					rounded
					href={routes.profile.path}
					active={pathname === routes.profile.path}
					icon={routes.profile.icon}
					label="Profil"
				>
					<div style={{ position: 'relative' }}>
						<Avatar variant="filled" radius="xl" size="sm">
							{userStateToAvatar(user)}
						</Avatar>
						{/* <div className={isLeader ? classes['leader-shadow'] : undefined} /> */}
					</div>
				</NavbarLink>

				<Space h="lg" />
				<Box mb={-12}>
					<NavbarLink onClick={() => logout()} icon={IconLogout} label="Logout" />
				</Box>
			</Stack>
		</Box>
	);
}

import { ContactAddress, Shipper } from '@packages/codegen/dhl_parcel_de_shipping_v2_client_browser';

import { OrderDoc } from '@/lib/db/models';

export const orderToDhlAddresses: (
	order: OrderDoc,
	senderAddress?: OrderDoc['senderAddress'],
) => { shipper: Shipper; consignee: ContactAddress } = (order, senderAddress) => {
	const _senderAddress = senderAddress ?? order.senderAddress;
	const _consigneeAddress = order.consigneeAddress;

	if (!_senderAddress) {
		throw new Error(`Sender address is required for DHL`);
	}

	if (!_consigneeAddress) {
		throw new Error(`Consignee address is required for DHL`);
	}

	return {
		shipper: {
			name1: _senderAddress.name1,
			name2: _senderAddress.name2,
			addressStreet: _senderAddress.street,
			addressHouse: _senderAddress.house,
			city: _senderAddress.city,
			postalCode: _senderAddress.zip,
			country: _senderAddress.country,
			email: _senderAddress.email,
		},
		consignee: {
			name1: _consigneeAddress?.name1,
			name2: _consigneeAddress?.name2,
			addressStreet: _consigneeAddress?.street,
			addressHouse: _consigneeAddress?.house,
			name3: _consigneeAddress.additionalInfo,
			/** ----------------------------------------------------------------------------------------------
			 * Please check the API specification. Those elements are not printed for most labels. Exceptions include Parcel International labels for selected countries.
			 * https://developer.dhl.com/api-reference/parcel-de-shipping-post-parcel-germany-v2#get-started-section/:~:text=additionalAddressInformation1%20and%20additionalAddressInformation2%20elements%20of%20the%20consignee%20address
			 * _______________________________________________________________________________________________ */
			// additionalAddressInformation1: 'lalelu',
			// additionalAddressInformation2: 'what the heck?',
			city: _consigneeAddress?.city,
			postalCode: _consigneeAddress?.zip,
			country: _consigneeAddress?.country,
			email: _consigneeAddress?.email,
		},
	};
};

import { notifications } from '@mantine/notifications';
import {
	LabelType,
	OrdersApi,
	ReturnOrderConfirmation,
} from '@packages/codegen/dhl_parcel_de_returns_v1_client_browser';
import {
	CreateOrdersPrintFormatEnum,
	ShipmentsAndLabelsApi,
	ShipmentsAndLabelsApiCreateOrdersRequest,
	VAS,
} from '@packages/codegen/dhl_parcel_de_shipping_v2_client_browser';
import * as dhl from '@packages/data/dhl-browser';
import * as CONFIG from '@packages/static/config';
import * as Sentry from '@sentry/nextjs';
import { AxiosInstance } from 'axios';
import { IterableElement } from 'type-fest';

import { OrderDoc } from '@/lib/db/models';
import { OrderErrorType } from '@/lib/db/order/literal';
import { DhlApp, DhlBillingNumbers, DhlLabelFormat } from '@/schema.types';
import { oxidAccessTokenAtom } from '@/state/state';
import { globalStore } from '@/state/store';

import { orderToDhlAddresses } from './dhl-utils';
import { CreateLabelOptions, ShipperConnector, ShippingOverrides } from './ShipperConnector';

export interface DhlCreateLabelOptions extends CreateLabelOptions {
	product: keyof DhlBillingNumbers;
	returnProduct?: keyof DhlBillingNumbers;
	services?: VAS;
}

export class DhlShipperConnector extends ShipperConnector {
	private client: AxiosInstance;
	private api: ShipmentsAndLabelsApi;

	private returnsApi: OrdersApi;

	private returnsClient: AxiosInstance;

	constructor(private readonly app: DhlApp) {
		super();

		this.client = dhl.createAxiosClient();

		this.client.defaults.baseURL = `${process.env.NEXT_PUBLIC_OXID_API_ORIGIN}/proxy/dhl/parcel/de/shipping/v2`;
		this.client.defaults.headers.common[CONFIG.params.headers.proxyApp] = app.id;
		this.client.defaults.withCredentials = true;

		this.returnsClient = dhl.createAxiosClient();

		this.returnsClient.defaults.baseURL = `${process.env.NEXT_PUBLIC_OXID_API_ORIGIN}/proxy/dhl/parcel/de/shipping/returns/v1`;
		this.returnsClient.defaults.headers.common[CONFIG.params.headers.proxyApp] = app.id;
		this.returnsClient.defaults.withCredentials = true;

		//

		// this.client.defaults.headers.common[CONFIG.params.headers.proxyEnvironment] = app.environment;

		// HINT: enabled this to bypass the cache
		// this.client.defaults.headers.common[CONFIG.params.headers.cache] = 'no-cache';

		this.api = new ShipmentsAndLabelsApi(undefined, undefined, this.client);
		this.returnsApi = new OrdersApi(undefined, undefined, this.returnsClient);
	}

	async createLabel(order: OrderDoc, options: DhlCreateLabelOptions, overrides?: ShippingOverrides) {
		this.client.defaults.headers.common['Authorization'] = globalStore.get(oxidAccessTokenAtom);
		this.returnsClient.defaults.headers.common['Authorization'] = globalStore.get(oxidAccessTokenAtom);

		const billingNumber = this.app.billingNumbers?.[options.product];

		if (!billingNumber) {
			throw new Error(`No billing number found for product ${options.product}`);
		}

		// let returnBillingNumber: string | undefined;

		// if (options.includeReturnLabel) {
		// 	if (options.returnProduct) {
		// 		returnBillingNumber = this.app.billingNumbers?.[options.returnProduct] ?? undefined;
		// 	}

		// 	if (!returnBillingNumber) {
		// 		throw new Error(`Could not determine return billing number`);
		// 	}
		// }

		const { shipper, consignee } = orderToDhlAddresses(order);

		if (!shipper) {
			throw new Error('Shipper is required');
		}

		const finalWeight = order.totalWeightGrams ?? overrides?.weightGrams;

		if (!finalWeight) {
			throw new Error('Weight is required');
		}

		let format: CreateOrdersPrintFormatEnum = CreateOrdersPrintFormatEnum._910300710;

		switch (this.app.defaultLabelFormat) {
			case DhlLabelFormat.Format_100x70mm:
				format = CreateOrdersPrintFormatEnum._100x70mm;
				break;
			case DhlLabelFormat.Format_910300300:
				format = CreateOrdersPrintFormatEnum._910300300;
				break;
			case DhlLabelFormat.Format_910300300Oz:
				format = CreateOrdersPrintFormatEnum._910300300Oz;
				break;
			case DhlLabelFormat.Format_910300400:
				format = CreateOrdersPrintFormatEnum._910300400;
				break;
			case DhlLabelFormat.Format_910300410:
				format = CreateOrdersPrintFormatEnum._910300410;
				break;
			case DhlLabelFormat.Format_910300600:
				format = CreateOrdersPrintFormatEnum._910300600;
				break;
			case DhlLabelFormat.Format_910300610:
				format = CreateOrdersPrintFormatEnum._910300610;
				break;
			case DhlLabelFormat.Format_910300700:
				format = CreateOrdersPrintFormatEnum._910300700;
				break;
			case DhlLabelFormat.Format_910300700Oz:
				format = CreateOrdersPrintFormatEnum._910300700Oz;
				break;
			case DhlLabelFormat.Format_910300710:
				format = CreateOrdersPrintFormatEnum._910300710;
				break;
			case DhlLabelFormat.FormatA4:
				format = CreateOrdersPrintFormatEnum.A4;
				break;
		}

		const requestParams = {
			includeDocs: 'URL',
			printFormat: format,
			retourePrintFormat: format,

			ShipmentOrderRequest: {
				profile: this.app.profile ?? '',

				shipments: [
					{
						product: options.product,
						billingNumber,

						refNo: order.eid,

						shipper,
						consignee,

						details: {
							weight: {
								uom: 'g',
								// HINT: DHL does not like weights below 15g it seems
								value: Math.max(15, finalWeight),
							},
						},

						services: options.services,

						// ...(options.includeReturnLabel && {
						// 	services: {
						// 		...(options.includeReturnLabel &&
						// 			returnBillingNumber && {
						// 				dhlRetoure: {
						// 					// billingNumber: this.app.receiverId!, // returnLabel.billingNumber,
						// 					billingNumber: returnBillingNumber, // returnLabel.billingNumber,
						// 					refNo: order.eid,
						// 					returnAddress: shipper,
						// 				},
						// 			}),
						// 	},
						// }),
					},
				],
			},
		} satisfies ShipmentsAndLabelsApiCreateOrdersRequest;

		const errors: IterableElement<OrderDoc['errors']>[] = [];

		const result = await this.api.createOrders(requestParams, { validateStatus: () => true });

		if (result.status < 200 || result.status >= 400) {
			const validationMessages = result.data.items
				?.map((item) => item.validationMessages?.map((validationItem) => validationItem.validationMessage).join(' '))
				.join(' | ');

			const error = new Error(`DHL Error: ${result.statusText} - ${validationMessages}`);

			Sentry.withScope((scope) => {
				scope.update((s) => ({ ...s, requestParams }));

				Sentry.captureException(error);
			});

			errors.push({
				cause: error,
				message: error.message,
				timestamp: new Date().toISOString(),
				type: OrderErrorType.SHIPMENT,
			});

			notifications.show({
				title: `DHL Fehler: ${result.statusText}`,
				message: validationMessages ?? 'Unbekannter Fehler',
				color: 'red',
			});

			return { errors };
		}

		let returnResult: ReturnOrderConfirmation | undefined;

		if (options.includeReturnLabel) {
			const returnResponse = await this.returnsApi.createReturnOrder(
				{
					// use QR label as this should be smaller
					labelType: LabelType.QrLabel,
					ReturnOrder: {
						receiverId: this.app.receiverId!,
						shipmentReference: order.eid,
						itemWeight: {
							uom: 'g',
							value: finalWeight,
						},
						shipper: {
							name1: consignee.name1,
							name2: consignee.name2,
							addressStreet: consignee.addressStreet,
							// WARN: we need the house for the return
							addressHouse: consignee.addressHouse!,
							city: consignee.city,
							// WARN: we need the postal code for the return
							postalCode: consignee.postalCode!,
							email: consignee.email,
							// WARN: no country !!!
						},
					},
				},
				{ validateStatus: () => true },
			);

			if (returnResponse.status < 200 || returnResponse.status >= 400) {
				const messages: string[] = [];

				if ('title' in returnResponse.data) {
					/**
					 * this can be
					 * "Bad Request"
					 */
					messages.push(returnResponse.data.title as string);
				}

				if ('detail' in returnResponse.data) {
					/**
					 * this can be
					 * "The configured billing numbers are not valid. Return labels can therefore not be created. Please contact your partner at DHL."
					 */
					messages.push(returnResponse.data.detail as string);
				}

				const error = new Error(`DHL Error ${returnResponse.status}: ${messages.join(' | ')}`);

				errors.push({
					message: error.message,
					timestamp: new Date().toISOString(),
					type: OrderErrorType.RETURN_SHIPMENT,
					cause: error,
				});

				return { errors };
			}

			returnResult = returnResponse.data;
		}

		// TODO: use sentry to log the error

		// console.log(returnResult);
		// console.log(result);

		// TODO: add return result and pass to order later
		return { shipmentResult: result, returnShipmentResult: returnResult };
	}
}
